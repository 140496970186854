<template>
  <div class="text-center">
    <div ref="mermaid01"></div>
  </div>
</template>

<script>
const mermaid = window.mermaid;

export default {
  watch: {
    graphDefinition: {
      handler() {
        this.initMap();
      },
    },
  },
  computed: {
    graphDefinition(){
      return `
        mindmap
          (HUBITER PAYMENTS MANAGER)
          ::icon(mdi mdi-credit-card)
            (${this.$t('mindMap1.i01')})
            ::icon(mdi mdi-check-bold)
              (${this.$t('mindMap1.i06')})
              (${this.$t('mindMap1.i07')})
              (${this.$t('mindMap1.i08')})
              (${this.$t('mindMap1.i09')})
            (${this.$t('mindMap1.i02')})
            ::icon(mdi mdi-robot-happy-outline)
              (${this.$t('mindMap1.i10')})
              (${this.$t('mindMap1.i11')})
            (${this.$t('mindMap1.i03')})
            ::icon(mdi mdi-chart-line)
              (${this.$t('mindMap1.i12')})
              (${this.$t('mindMap1.i13')})
              (${this.$t('mindMap1.i14')})
              (${this.$t('mindMap1.i15')})
            (${this.$t('mindMap1.i04')})
            ::icon(mdi mdi-alert)
              (${this.$t('mindMap1.i16')})
            (${this.$t('mindMap1.i05')})
            ::icon(mdi mdi-face-agent)
              (${this.$t('mindMap1.i17')})
      `;
    }
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap(){
      const diagramContainer = this.$refs.mermaid01;
      diagramContainer.innerHTML = '';

      // Inicializar Mermaid.js después de que el componente se haya montado
      mermaid.initialize({
        startOnLoad: true,
        theme: 'base',
        themeVariables: {
          primaryTextColor: '#000000'
        }
      });

      mermaid.render('mermaid01', this.graphDefinition).then(({svg}) => {
        diagramContainer.innerHTML = svg;
      });
    }
  }
};
</script>

<style>
/* .icon-container .mdi{
  color: #367543 !important
} */
.node-rounded-rect,
.node rect,
.node circle,
.node ellipse {
  fill: #B2DFDB !important;
  color: #FFFFFF !important;
}

.edge,
.link {
  stroke: #64916E50 !important;
}
</style>

<template> 
  <section>
    <top-section 
    :image="PayImage"
      :title="$t('home.top.title')"
      :description="$t('home.top.description')"
    ></top-section>

    <description-section
      :title="$t('home.descriptionSection.title')"
      :content="$t('home.descriptionSection.content')"
    ></description-section>

    <ExampleMap></ExampleMap>
    
    <features-section
      :title="$t('home.featuresSection.title')"
      :description="$t('home.featuresSection.description')"
      :items="$t('home.featuresSection.items')"
    >
    </features-section>

    <offers-section
      :items="$t('home.offersSection.items')"
    ></offers-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopSection from "../sections/TopSection.vue";
import DescriptionSection from "../sections/DescriptionSection.vue";
import OffersSection from "../sections/OffersSection.vue";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";
import PayImage from "../../assets/pay.jpg"
import ExampleMap from "../mindmaps/Example.vue";

export default {
  name: "Home",
  components: {
    TopSection,
    DescriptionSection,
    OffersSection,
    FeaturesSection,
    ContactSection,
    ExampleMap
  },
  
  data() {
    return {
      PayImage: PayImage,
    };
  }
};
</script>
